import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api';
import { Container, VariantChooser, NativeSlider, useShopConfig, useProductDetailLink } from '@ttstr/components';
import PreviewImage from '@ttstr/components/Sushi/PreviewImage';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();

  const heroImageAsArray = product.hero_image.url ? [product.hero_image.url] : [];
  const images = React.useMemo(() => [...heroImageAsArray, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const previewImage = product.image_thumb;
  // const heroThumbAsArray = product.hero_image.thumb.url ? [product.hero_image.thumb.url] : [];
  // const thumbs = React.useMemo(() => [...heroThumbAsArray, ...product.gallery_image_urls.map((i) => i.thumb)], [
  //   product,
  // ]);

  return (
    <article className="product-detail my-0" itemScope itemType="http://schema.org/Product">
      <Helmet>
        <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
      </Helmet>
      <meta itemProp="sku" content={String(product.id)} />

      <header className="product-name">
        {product.supertitle && <span className="text-muted product-supertitle">{product.supertitle}</span>}
        <h1 className="my-5 mt-0">
          <span itemProp="name" className="product-title">
            {product.title}
          </span>
          <small className="product-subtitle d-none">{product.subtitle}</small>
        </h1>
      </header>

      {/* {Boolean(images?.length) && (
        <header className="product-header">
          <NativeSlider images={images} previewImage={previewImage} />
        </header>
      )} */}

      <section className="product-content">
        <Container fluid className="detail-page my-0 py-0">
          <Row>
            {/* <Col md={6} className="px-0 pr-md-3">
              <header className="text-center text-lg-left">
                {product.supertitle && <span className="text-muted product-supertitle">{product.supertitle}</span>}
                <h1 className="display-5 my-2 mt-0">
                  <span itemProp="name" className="product-title">
                    {product.title}
                  </span>
                  <small className="product-subtitle d-none">{product.subtitle}</small>
                </h1>
              </header>
            </Col> */}

            <Col md={6} className="px-0 pr-md-3">
              <NativeSlider images={images} previewImage={previewImage} />
              {/* {Boolean(images?.length) && (
                <div className="d-none d-md-block">
                  {images.map((image, index) => (
                    <PreviewImage
                      key={image}
                      src={image}
                      previewSrc={previewImage}
                      alt={t(`PRODUCT.IMAGE_INDEX`, { index: index + 1 })}
                      className="w-100"
                      itemProp="image"
                      loading="lazy"
                    />
                  ))}
                </div>
              )} */}
            </Col>
            <Col md={6} xl={{ size: 4, offset: 1 }}>
              <section className="product-info sticky-top product-detail-right">
                <VariantChooser className="mb-4" product={product} />

                {product.description && (
                  <React.Fragment>
                    <h2 className="product-title">{t(`PRODUCT.INFORMATION`) + ' :'}</h2>
                    <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                  </React.Fragment>
                )}
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
